<!-- eslint-disable no-unused-vars -->
<script setup>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import store from '../../../state/store';
import { callApi } from '../../../msal/msalApi';
import { reactive, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'

const { email, id } = useRoute().params;
const { inputSearch, selectFranchise, selectLocation, memberOnly,statusFilter } = useRoute().query

let data = reactive({
    firstName: '',
    lastName: '',
    email: '',
    selectGroup: '',
    selectLocation: '',
    selectRol: '',
    practiceGroupArray: [],
    locationArray: [],
    rolesArray: [],

    userEditData: [],

    checkName: false,
    checkLast: false,
    checkGroup: false,

    practiceRol: '',
    accountId: '',
    franchise_ID: '',
    franchiseName: '',
    memberStatus: undefined,

    canEditUser: undefined,
    canModifyLocations: undefined,
    canModifyMemberRole: undefined,
    canSetRemoteAccess: undefined,

    remoteAccess: false,


    practitioner: false,
    OfficeAdmin: false,
    CoachManager: false,
    Coach: false,
    locationID: '',

    url: '',
    msg: '',

    groupTitle: '',

    userLocations: []
})
console.log(data.practitioner, data.Coach, data.CoachManager, data.OfficeAdmin)

async function getPracticeGroup() {
    data.url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${store.getters.getEmail}&sortByList[0].Name=FranchiseName&sortByList[0].Direction=Asc&sortByList[1].Name=LastName&sortByList[1].Direction=Desc`;
    const userData = `${process.env.VUE_APP_WLT_API}Associate/Get?email=${email}&franchiseID=${id}`;

    if (data.selectGroup) {
        data.url += `&franchiseIdFilters[0]=${data.selectGroup}`;
    } else if (data.selectLocation) {
        data.url += `&franchiseLocationIdFilters[0]=${data.selectLocation}`;
    }

    try {
        const [response, response2] = await Promise.all([
            callApi(data.url, store.getters.getToken),
            callApi(userData, store.getters.getToken),
        ]);

        const { franchiseSelections, franchiseLocations, practiceRoles } = response;

        data.practiceGroupArray = franchiseSelections;
        data.locationArray = franchiseLocations;
        data.rolesArray = practiceRoles;

        const { practiceLocations } = response2
        const [{ firstName, lastName, email }] = practiceLocations;

        data.firstName = firstName;
        data.lastName = lastName;
        data.email = email;

        data.userEditData = practiceLocations
        data.accountId = data.userEditData[0].userAccount_ID
        data.franchise_ID = response2.franchiseId
        data.franchiseName = data.userEditData[0].franchiseName
        data.practiceRol = data.userEditData[0].userFranchiseRole_ID
        data.memberStatus = data.userEditData[0].memberStatus
        data.remoteAccess = data.userEditData[0].isRemote

        data.canEditUser = response2.canEditUser
        data.canModifyLocations = response2.canModifyLocations
        data.canModifyMemberRole = response2.canModiyMemberRole
        data.canSetRemoteAccess = response2.canSetRemoteAccess

        data.selectGroup = data.userEditData[0].franchise_ID

        data.userLocations = response2.practiceLocations

        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>")

        console.log(data.userEditData)

        console.log(inputSearch, selectFranchise, selectLocation, memberOnly,JSON.parse(statusFilter) )

    } catch (error) {
        console.error(error);
    }
}


const filteredLocationArray = computed(() => {
    const userLocationIds = data.userLocations.map(location => location.franchiseLocation_ID);
    const filteredLocations = data.locationArray.filter(location => !userLocationIds.includes(location.franchiseLocation_ID));

    if (filteredLocations.length === 0) {
        return [0];
    }

    return filteredLocations;
});




async function sendUpdateForm() {
    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/Update?UserAccountId=${data.accountId}&FirstName=${data.firstName}&LastName=${data.lastName}&Email=${data.email}&Remote=${data.remoteAccess}&Franchise_ID=${data.franchise_ID}&PracticeGroupRole=${data.practiceRol}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${store.getters.getToken}`
            }
        });


        if (response.ok){
            return Swal.fire(`<p class="h6">
            <p class="h5 fw-bold">${data.email}</p> has been updated 
            <p class="h5 fw-bold">${data.franchiseName}</p> 
            <p/>`

            , '', 'success')

        }else{
            Swal.fire('Error!', '', 'error')
        }


        console.log(response);
    } catch (error) {
        Swal.fire('Error!', '', 'error')

        console.log(error.message);
    }

    getPracticeGroup()

}

async function updateRol(locationId, index) {
    const { practitioner, CoachManager, OfficeAdmin, Coach, accountId, franchise_ID, selectLocation } = data;

    // Guardar el estado anterior de userData
    const prevUserData = {
        isPractitioner: data.userEditData[index].isPractitioner,
        isCoachManager: data.userEditData[index].isCoachManager,
        isOfficeManager: data.userEditData[index].isOfficeManager,
        isCoach: data.userEditData[index].isCoach,
    };
    console.log(data.userEditData)
    let userData = {
        "userAccount_ID": accountId,
        "franchise_ID": franchise_ID,
        "franchiseLocation_ID": locationId,
        "isPractitioner": practitioner,
        "isCoachManager": CoachManager,
        "isOfficeManager": OfficeAdmin,
        "isCoach": Coach,
    }

    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/MemberLocationUpdate`;

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(userData),
            headers: {
                Authorization: `Bearer ${store.getters.getToken}`,
                "Content-Type": "application/json"
            }
        });

        if (response.status == 400) {
            Swal.fire('Error!', '', 'error')
        } else {
            // Comparar el estado anterior y actual de userData para ver qué roles se han añadido o eliminado
            const addedRoles = [];
            const removedRoles = [];
            if (userData.isPractitioner !== prevUserData.isPractitioner) {
                if (userData.isPractitioner) {
                    addedRoles.push('Practitioner');
                } else {
                    removedRoles.push('Practitioner');
                }
            }
            if (userData.isCoachManager !== prevUserData.isCoachManager) {
                if (userData.isCoachManager) {
                    addedRoles.push('Coach Manager');
                } else {
                    removedRoles.push('Coach Manager');
                }
            }
            if (userData.isOfficeManager !== prevUserData.isOfficeManager) {
                if (userData.isOfficeManager) {
                    addedRoles.push('Office Manager');
                } else {
                    removedRoles.push('Office Manager');
                }
            }
            if (userData.isCoach !== prevUserData.isCoach) {
                if (userData.isCoach) {
                    addedRoles.push('Coach');
                } else {
                    removedRoles.push('Coach');
                }
            }

            if (addedRoles.length > 0 && removedRoles.length > 0) {
                Swal.fire(`Roles updated: ${removedRoles.join(', ')} removed and ${addedRoles.join(', ')} added to user  ${email}`, '', 'success');
            } else if (addedRoles.length > 0) {
                Swal.fire(`Roles added: ${addedRoles.join(', ')}  to user  ${email}`, '', 'success');
            } else if (removedRoles.length > 0) {
                Swal.fire(`Roles removed: ${removedRoles.join(', ')}  from user ${email}`, '', 'success');
            } else {
                Swal.fire('No roles updated', '', 'success');
            }
        }
        console.log(response)
        console.log(userData)
    } catch (error) {
        console.log(error.message);
    }

    getPracticeGroup();
}


async function addNewLocation() {
    let { firstName, lastName, email, practitioner, CoachManager, OfficeAdmin, Coach, accountId, franchise_ID, selectLocation, remoteAccess } = data

    practitioner = false
    OfficeAdmin = false
    CoachManager = false
    Coach = true
    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/MemberLocationUpdate`;

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(

                {
                    "userAccount_ID": accountId,
                    "franchise_ID": franchise_ID,
                    "franchiseLocation_ID": selectLocation,
                    "isPractitioner": practitioner,
                    "isCoachManager": CoachManager,
                    "isOfficeManager": OfficeAdmin,
                    "isCoach": Coach
                }

            ),
            headers: {
                Authorization: `Bearer ${store.getters.getToken}`,
                "Content-Type": "application/json"
            }
        });

        if (response.status == 400) {
            Swal.fire('Error!', '', 'error')
        } else {
            Swal.fire(`<p class="h5"> ${data.email} a new location has been added ${data.franchiseName}</p>`, '', 'success')

        }
        console.log(response)
        console.log(selectLocation)


    } catch (error) {

        console.log(error.message);
    }

    getPracticeGroup()
}

async function deactivateUser() {
    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/${data.memberStatus === 1 ? "deactivate" : "activate"}?userEmail=${data.email}&franchiseID=${data.franchise_ID}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${store.getters.getToken}`
            }
        });

        console.log(url)
        if (response.ok) {
            Swal.fire(`<p class="h5"> ${data.email} has been Deactivate ${data.franchiseName}</p>`, '', 'success')
            getPracticeGroup()

        } else {
            Swal.fire('Error!', '', 'error')

        }


        console.log(response);
    } catch (error) {
        Swal.fire('Saved!', '', 'error')

        console.log(error.message);
    }
}

async function removeLocation(locationId, locationName) {
    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/RemoveFromLocation?userEmail=${data.email}&franchiseLocationID=${locationId}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${store.getters.getToken}`
            }
        });

        console.log(url)
        console.log(response);

        if (response.ok) {
            getPracticeGroup()
            return Swal.fire(`<p class="h5"> ${data.email} has been Remove from ${locationName}</p>`, '', 'success')
        }
        Swal.fire('ERROR!', '', 'error')

    } catch (error) {

        console.log(error.message);
    }
}


function getRoleDescription(role) {
    let roles = [];

    if (role.isCoach) {
        roles.push("Coach");
    }

    if (role.isCoachManager) {
        roles.push("Coach Manager");
    }

    if (role.isOfficeManager) {
        roles.push("Office Admin");
    }

    if (role.isPractitioner) {
        roles.push("Practitioner");
    }

    if (roles.length === 0) {
        return ""
    }

    return roles.join(", ");

}

// Función para asignar valores de roles a las directivas v-model
function assignRoleValues(franchiseLocationId) {
    // Buscamos el objeto en el array que tenga el franchiseLocationId buscado
    const locationObj = data.userLocations.find(obj => obj.franchiseLocation_ID === franchiseLocationId.franchiseLocation_ID);

    // Si lo encontramos, asignamos los valores correspondientes a las directivas v-model
    if (locationObj) {
        data.practitioner = locationObj.isPractitioner;
        data.OfficeAdmin = locationObj.isOfficeManager;
        data.CoachManager = locationObj.isCoachManager;
        data.Coach = locationObj.isCoach;
        console.log(locationObj)

        data.groupTitle = locationObj.locationName

    }
    //console.log(franchiseLocationId)
}


onMounted(() => {
    getPracticeGroup()
    if (data.selectGroup) {
        data.url += `&franchiseIdFilters[0]=${data.selectGroup}`;
    }
})
</script>
    
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <h2>Edit User</h2>
    </Layout>


    <div class="container mb-5">
        <div class="d-flex justify-content-between mb-3">
            <div></div>

            <router-link :to="{
                    path: '/Practitioner/Coach/Member',
                    query: {
                        inputSearch: inputSearch,
                        selectLocation: selectLocation,
                        selectFranchise: selectFranchise,
                        memberOnly: memberOnly,
                        statusFilter:statusFilter
                    }
                }">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"
                    style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                    <path
                        d="M9 10h6c1.654 0 3 1.346 3 3s-1.346 3-3 3h-3v2h3c2.757 0 5-2.243 5-5s-2.243-5-5-5H9V5L4 9l5 4v-3z">
                    </path>
                </svg>
            </router-link>



        </div>


        <form>
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-5 mb-3 m-form">

                    <div class="d-flex mt-1">
                        <input type="text" class="form-control" id="Practice" placeholder="Practice Group Name" disabled
                            v-model="data.franchiseName">
                    </div>

                    <div class="row mt-2 text-center">
                        <div class="col d-flex">
                            <input type="text" class="form-control" id="firstName" placeholder="First Name"
                                v-model="data.firstName">
                        </div>
                        <div class="col d-flex">
                            <input type="text" class="form-control" id="lastName" placeholder="Last Name"
                                v-model="data.lastName">
                        </div>

                    </div>
                    <input type="email" class="form-control mt-2" id="email" placeholder="email@email.com"
                        v-model="data.email">


                    <div class="row">
                        <div class="col col-md-6">
                            <select @click="selectPracticeRol" class="form-select mt-2" v-model="data.practiceRol"
                                :disabled="!data.canModifyMemberRole">
                                <option value="FRANCHISEE">Practice Group Owner</option>
                                <option value="FRANCHISEADMIN">Practice Group Admin</option>
                                <option value="ASSOCIATE">Practice Group Member</option>
                            </select>
                        </div>
                        <div class="col d-flex flex-row" v-if="data.canEditUser">
                            <button class="btn mx-1 w-75"
                                :class="[{ 'btn-danger': data.memberStatus === 1 }, { 'btn-info': data.memberStatus === 2 }, 'float-end', 'mt-2', 'fw-bold', 'mx-3']"
                                @click.prevent="deactivateUser">
                                {{ data.memberStatus === 1 ? 'Deactivate' : 'Activate' }}
                            </button>


                            <button class="btn btn-success w-75 mt-2" type="submit"
                                @click.prevent="sendUpdateForm">Update</button>
                        </div>
                        <div class="form-check mx-3 mt-2" v-if="data.canSetRemoteAccess">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                v-model="data.remoteAccess">
                            <label class="form-check-label fw-bold" for="flexCheckDefault">
                                Intelligence Remote Access
                            </label>
                        </div>
                        <div v-else>
                            <label class="form-check-label fw-bold" for="flexCheckDefault">
                                Intelligence Remote Access: {{ data.remoteAccess ? 'Enabled' : 'Disabled' }}
                            </label>
                        </div>
                    </div>

                </div>


                <div class="fixsize ">
                    <table class="table table-dark table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Location</th>
                                <th scope="col">Roles</th>
                                <th>
                                    <li class="list-item fw-bold">
                                        <button type="button" class="btn btn-primary fw-bold" data-bs-toggle="modal"
                                            data-bs-target="#addLocation" @click="getPracticeGroup" v-if="data.canEditUser">
                                            add Location
                                        </button>

                                        <div class="modal fade" id="addLocation" data-bs-backdrop="static"
                                            data-bs-keyboard="false" tabindex="-1" aria-labelledby="addLocationLabel"
                                            aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered">
                                                <div class="modal-content">
                                                    <div class="modal-body">
                                                        <template v-if="filteredLocationArray[0] === 0">
                                                            <p class="fw-bold h3 text-primary text-center">This user belongs
                                                                to all locations</p>
                                                        </template>
                                                        <select v-else name="selectLocation" id=""
                                                            class="col mx-2 form-control" v-model="data.selectLocation">
                                                            <option value="" selected>Location</option>
                                                            <option :value="franchise.franchiseLocation_ID"
                                                                v-for="(franchise, index) in filteredLocationArray"
                                                                :key="index">
                                                                {{ franchise.locationName }}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <template v-if="filteredLocationArray[0].franchiseName">
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-dismiss="modal" @click="addNewLocation">Add
                                                                changes</button>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal">Cancel</button>
                                                        </template>
                                                        <template v-else>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal">Close</button>
                                                        </template>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                    </li>

                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, index) in data.userEditData" :key="index">
                                <th scope="row">
                                    <button :id="'deleteBtn-' + index" class="btn border text-danger fw-bold mx-1"
                                        @click.prevent="removeLocation(item.franchiseLocation_ID, item.locationName)"
                                        v-if="data.canModifyLocations">X</button>
                                    {{ item.locationName }}
                                </th>
                                <td>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-item mb-4 fw-bold">
                                            {{ getRoleDescription(item) }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-item fw-bold">
                                            <button type="button" class="btn" data-bs-toggle="modal"
                                                @click="assignRoleValues(item)"
                                                v-bind:data-location-id="item.franchiseLocation_ID"
                                                :data-bs-target="'#editRoleModal-' + index" v-if="data.canEditUser">
                                                <i class='bx bx-edit h3  text-white'></i>
                                            </button>
                                            <div class="modal fade" :id="'editRoleModal-' + index" tabindex="-1"
                                                aria-labelledby="editRoleModalLabel" aria-hidden="true" data-bs-backdrop="static">
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-body">
                                                            <p class="text-dark h4 mb-4">{{ data.groupTitle }}</p>

                                                            <div class="check-container"
                                                                v-bind:data-location-id="item.franchiseLocation_ID">
                                                                <div class="form-check mb-3 h5">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-bind:checked="item.isPractitioner"
                                                                        @change="data.practitioner = !data.practitioner">
                                                                    <label
                                                                        class="form-check-label fw-bold text-dark">Practitioner</label>
                                                                </div>
                                                            </div>

                                                            <div class="check-container">
                                                                <div class="form-check mb-3 h5">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-bind:checked="item.isOfficeManager"
                                                                        @change="data.OfficeAdmin = !data.OfficeAdmin">
                                                                    <label
                                                                        class="form-check-label fw-bold text-dark">OfficeAdmin</label>
                                                                </div>
                                                            </div>
                                                            <div class="check-container">
                                                                <div class="form-check mb-3 h5">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-bind:checked="item.isCoachManager"
                                                                        @change="data.CoachManager = !data.CoachManager">
                                                                    <label
                                                                        class="form-check-label fw-bold text-dark">CoachManager</label>
                                                                </div>
                                                            </div>

                                                            <div class="check-container">
                                                                <div class="form-check mb-3 h5">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        v-bind:checked="item.isCoach"
                                                                        @change="data.Coach = !data.Coach">
                                                                    <label
                                                                        class="form-check-label fw-bold text-dark">Coach</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-primary"
                                                                data-bs-dismiss="modal"
                                                                @click="updateRol(item.franchiseLocation_ID, index)">Add
                                                            </button>
                                                            <button type="button" class="btn btn-danger"
                                                                data-bs-dismiss="modal">cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="modal fade" :id="'editRoleModal-' + index" tabindex="-1"
                                                aria-labelledby="editRoleModalLabel" aria-hidden="true">
                                            </div>
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </form>
    </div>
</template>

<style>
:root {
    --fixsize-margin-left: 14rem;
    --fixsize-width: 75%;
    --m-form-margin-left: 15rem;
    --m-form-width: 50%;
}

#contenedorLocation {
    height: 300px;
    overflow-y: scroll;
}

.list-item {
    list-style: none;
}


@media screen and (min-width: 876px) {
    .fixsize {
        margin-left: var(--fixsize-margin-left);
        width: var(--fixsize-width);
    }

    .m-form {
        margin-left: var(--m-form-margin-left);
        width: var(--m-form-width);
    }
}

@media screen and (max-width: 917px) {
    .fixsize {
        margin-left: 0;
        width: 100%;
    }

    .m-form {
        margin-left: 0;
        width: 100%;
    }
}
</style>
